@import "variables";

.no-border {
    border: none !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

::-webkit-input-placeholder { /* Edge */
    color: #a2a2a2 !important;
    font-size: 15px !important;
}
  
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #a2a2a2 !important;
    font-size: 15px !important;
}
  
::placeholder {
    color: #a2a2a2 !important;
    font-size: 15px !important;
}

.ant-select-item-option {
    color: #63717b;
    font-size: 15px;
    font-family: "Open sans regular", serif;
    &.ant-select-item-option-active, &.ant-select-item-option-selected {
        background-color: rgb(231, 231, 231) !important;
        color: #63717b !important;
    }
}

.ant-form-item-explain {
    &.ant-form-item-explain-error {
        font-size: 16px;
        color: #FF3358;
        font-family: "Open sans regular", serif;
    }
}

// COLOR
.color-black {
    color: black !important;
}
.color-white {
    color: white !important;
}
.color-grey {
    color: @color-default-grey !important;
}
.color-transparent {
    color: transparent !important;
}
.color-light-grey {
    color: @color-light-grey !important;
}
.color-blue {
    color: @color-blue !important;
}
.color-green {
    color: @color-green !important;
}
.color-yellow {
    color: @color-yellow !important;
}
.color-red {
    color: @color-red !important;
}


.border-red {
    border: 1px @color-red solid !important;
}

.bg-white {
    background-color: @color-white !important;
}
.bg-blue {
    background-color: @color-blue !important;
}

// ALIGN
.align-center {
    text-align: center !important;
}
.align-right {
    text-align: right !important;
}

.space-nowrap {
    white-space: nowrap !important;
}

.space-normal {
    white-space: normal !important;
}

.text-overflow-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-lowercase {
    text-transform: lowercase !important;
}

// BORDER
.border-bottom {
    border-bottom: 1px @color-light-grey solid !important;
}
.border-top {
    border-top: 1px @color-light-grey solid !important;
}

// FORM
.ant-form-hide-required-mark {
    .ant-form-item-label {
        label {
            &.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
                display: none !important;
            }
        }
    }
}

// CAROUSEL
.ubot-carousel {
    padding-bottom: 20px;
    .slick-dots {
        margin-bottom: 0;
        li {
            width: 14px;
            button {
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background-color: @color-white;
                border: 1px @color-light-grey solid;
            }
            &.slick-active {
                width: 14px;
                button {
                    width: 14px;
                    height: 14px;
                    background-color: @color-light-grey;
                }
            }
        }
    }
}

// OTHER
.vertical-step-ul {
    list-style: none;
    padding: 0;
    counter-reset: my-awesome-counter;
    li {
        padding-bottom: 45px;
        padding-left: 35px;
        counter-increment: my-awesome-counter;
        position: relative;
        &::before {
            content: counter(my-awesome-counter);
            position: absolute;
            left: 0;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background-color: @color-blue;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &::after {
            content: "";
            width: 3px;
            height: 20px;
            position: absolute;
            top: 30px;
            background-color: @color-blue;
            left: 11px;
            border-radius: 3px;
        }
        &:last-child::after {
            content: none;
        }
    }
}

.quill {
    .ql-toolbar.ql-snow {
        border-color: @color-light-grey;
        border-radius: 4px 4px 0 0;
        font-family: "Open sans regular", serif;
    }
    .ql-container.ql-snow {
        border-color: @color-light-grey;
        color: @light-grey;
        border-radius: 0 0 4px 4px;
        font-family: "Open sans regular", serif;
        font-size: 15px;
    }
}

.confirm-modal {
    .modal-content {
        border-color: @color-red;
        img {
            margin: auto;
            display: block;
        }
    }
}

.ant-divider {
    border-top-color: #a2a2a280;
}

.scroll-x {
    overflow-x: scroll;
}

.scroll-y {
    overflow-y: scroll;
}

@media (min-width: 414px) {
    .scroll-x::-webkit-scrollbar {
        height: 4px;
        background-color: transparent;
        border-radius: 4px;
    }

    .scroll-x::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        background-color: transparent;
        border-radius: 4px;
    }

    .scroll-x::-webkit-scrollbar-thumb {
        background-color: #a3a3a3;
        border: 1px solid #a3a3a3;
        border-radius: 4px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0)
    }

    .scroll-y::-webkit-scrollbar {
        width: 4px;
        background-color: transparent;
    }

    .scroll-y::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        background-color: transparent;
    }

    .scroll-y::-webkit-scrollbar-thumb {
        background-color: #a3a3a3;
        border: 2px solid #a3a3a3;
        border-radius: 4px;
    }
}